import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList, CdkDrag, CdkDragPlaceholder } from '@angular/cdk/drag-drop';
import { Activity, Phase, Priority, Template } from '../../../../core/models/interfaces';
import { DataproviderService } from '../../../../core/services/dataprovider.service';
import { NotificationService } from '../../../../core/services/notification.service';
import { startWith, switchMap, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cdkDragMove, DropDirectionState, getDirectionState } from '../../../../_helpers/helpers';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { GoogleContactsInterface } from '../../../google/services/google-contacts.service';
import { ActivatedRoute } from '@angular/router';
import { ActivityManagementService } from '../../../../core/services/activity-management.service';
import { PhaseSorter } from '../../../../_helpers/phase-sorter';
import { MatIcon } from '@angular/material/icon';
import { ActivityListEntryComponent } from '../activity-list-entry/activity-list-entry.component';
import { NgIf, NgFor } from '@angular/common';
import { ActivitiesService } from '../planning-board/services/activities.service';
import { ActivityStatus, AssignedGroupContainer } from '../planning-board/helpers/planning-board.helper';
import { PhasesService } from '../../../../core/services/phases.service';
import { of } from 'rxjs';

// the component holds all sorted activities per date
@UntilDestroy()
@Component({
    selector: 'app-activity-list',
    templateUrl: './activity-list.component.html',
    standalone: true,
    imports: [
      NgIf,
      NgFor,
      CdkDrag,
      CdkDropList,
      CdkDragPlaceholder,
      ActivityListEntryComponent,
      MatIcon,
      TranslateModule,
    ],
})
export class ActivityListComponent implements OnInit, AfterViewInit {
  @Input() phase!: Phase;
  @Input() peopleContacts: GoogleContactsInterface[] = [];
  @Input() template: Template;
  @Input() date!: string;
  @Input() priorityPainterClass!: string;
  @Input() dayEditor: boolean = true;
  @Input() googleContactsEnabled: boolean = false;
  @Input() priorities: Priority[] = [];
  @Input() groupsWithTasks: AssignedGroupContainer[];
  @Input() activityStatuses: ActivityStatus[];

  public cdkDragMove = cdkDragMove;
  public activities: Activity[] = [];

  constructor(
    private dataProviderService: DataproviderService,
    private notificationService: NotificationService,
    private _phasesService: PhasesService,
    private translate: TranslateService,
    private activityManagementService: ActivityManagementService,
    private route: ActivatedRoute,
    private activitiesService: ActivitiesService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this._phasesService.onRefreshDays$
      .pipe(startWith(undefined), untilDestroyed(this))
      .subscribe(() => this._refreshData());
  }

  ngAfterViewInit(): void {
    this.showActivityManagementBlockIfHaveQueryParams();
  }

  private _refreshData(): void {
    let activities_tmp = this.phase.activities.filter((activity) => !activity.phaseDefault);

    // Restore activities order if ordering information exists
    // else leave the order as is
    if (!this.phase.orderActivities) {
        this.activities = activities_tmp;
        return;
    }

    let activities_order = JSON.parse(this.phase.orderActivities);

    if (activities_order.length === activities_tmp.length) {

        // Clear activities array
        this.activities.length = 0;

        // Fill activities array in right order
        for (let id of activities_order) {
          for (let activity of activities_tmp) {
            if (activity.id === id) {
              this.activities.push(activity);
          }
          }
        }
        this.activities = PhaseSorter.recalculateStartEndTime(this.activities, this.phase.fixedStart);
      } else {
        this.activities = activities_tmp;
      }
  }

  public addActivity(): void {
    const activityDuration = 15;
    this._phasesService.addNewActivityToPhase(
      this.phase,
      '',
      activityDuration,
      null,
    ).pipe(take(1), untilDestroyed(this))
      .subscribe(() => {
        this.notificationService.success({
          title: this.translate.instant(marker('Keep it up...')),
          description: this.translate.instant(marker('Activity added successfully')),
        });
      });
  }

  public drop(event: CdkDragDrop<Activity[]>): void {
    this.cdkDragMove.next(true);

    if (event.previousContainer === event.container && event.currentIndex === event.previousIndex || this.phase === undefined) {
      return;
    }

    let prevActivity = event.previousContainer.data[event.previousIndex];
    let toActivity;
    let dir: DropDirectionState = DropDirectionState.After;

    if (event.previousContainer === event.container) {
      dir = getDirectionState(event);
      prevActivity = event.container.data[event.previousIndex];
      toActivity = event.container.data[event.currentIndex];

      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if (event.currentIndex === 0) {
        const actPhaseDefault = this.phase?.activities.filter((activity) => !!activity.phaseDefault).pop();
        if (actPhaseDefault) {
          toActivity = actPhaseDefault;
        }
      } else {
        toActivity = event.container.data[event.currentIndex - 1];
      }

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }

    // Save activities order
    var activities_order = [];
    for (let data of event.container.data) {
      activities_order.push(data.id);
    }
    this.phase.orderActivities = JSON.stringify(activities_order);
    this._phasesService.updatePhase(this.phase)
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: () => {
          this._moveActivity(prevActivity, toActivity, dir, event);
        },
        error: (error) => {
          this.resetActivitiesSorting(event);
          this.notificationService.error({
            title: this.translate.instant(marker('Failed to move activity')),
            description: error,
          });
        },
      });
  }

  private _moveActivity(prevActivity, toActivity, dir, event): void {
    this.dataProviderService.moveActivityById(
      prevActivity,
      toActivity,
      dir,
    ).pipe(
      take(1),
      switchMap((res) => {
        if (prevActivity.in_clipboard === true) {
          prevActivity.in_clipboard = false;
          prevActivity.phase = this.phase;
          prevActivity.order_in_clipboard = null;
          return this.activitiesService.updateActivityByPk$(prevActivity);
        } else {
          return of(res);
        }
      }),
      untilDestroyed(this),
    ).subscribe({
      error: () => {
        this.resetActivitiesSorting(event);
      },
    });
  }

  private showActivityManagementBlockIfHaveQueryParams(): void {
    const activityIdFromParams = this.route.snapshot.queryParamMap.get('activityId');

    if (!!activityIdFromParams) {
      const foundedActivityById = this.activities.find((el) => +el.id === +activityIdFromParams);

      if (!foundedActivityById) {
        return;
      }

      // Update Start and End time for activities
      this.activities = PhaseSorter.recalculateStartEndTime(this.activities, this.phase.fixedStart);
      this.activityManagementService.setActivityData(foundedActivityById);
    }
    this.cdr.detectChanges();
  }

  private resetActivitiesSorting(event: CdkDragDrop<Activity[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.activities, event.currentIndex, event.previousIndex);
    } else {
      transferArrayItem(
        event.container.data,
        event.previousContainer.data,
        event.currentIndex,
        event.previousIndex,
      );
    }
  }
}
