import { NotificationService, ToastTypes } from '../../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';

export abstract class ShowToastEffect {

    constructor(
        private _notificationService: NotificationService,
        private _translate: TranslateService,
    ) {}

    public showNotification(type: ToastTypes, msg: string, title: string | null = null) {
        this._notificationService[type]({
            // eslint-disable-next-line no-extra-parens
            ...(title ? { title: this._translate.instant(title) } : {}),
            description: this._translate.instant(msg),
        });
    }
}
