import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '../../../modules/auth/services/auth.service';
import { NgProgressService } from '../../../shared/services/ng-progress.service';
import { DayEditorOnboardingIdEnum } from '../objects/onboarding-tooltips/onboarding-tooltips.helper';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSelectionComponent } from '../objects/language-selection/language-selection.component';
import { ThemeSwitcherComponent } from '../objects/theme-switcher/theme-switcher.component';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatRipple } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'app-sidebar-menu',
    templateUrl: './sidebar-menu.component.html',
    styleUrls: ['./sidebar-menu.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        RouterLinkActive,
        MatIcon,
        MatRipple,
        MatMenuTrigger,
        MatMenu,
        MatMenuItem,
        ThemeSwitcherComponent,
        LanguageSelectionComponent,
        RouterOutlet,
        TranslateModule,
    ],
})
export class SidebarMenuComponent implements OnInit {
  readonly DayEditorOnboardingIdEnum = DayEditorOnboardingIdEnum;

  constructor(
    public authService: AuthService,
    private progressService: NgProgressService,
  ) {
  }

  ngOnInit(): void {}

  get dropdownName() {
    let nameResult = '';

    this.authService.getUser?.display_name.split(' ').forEach((word) => {
      if (!word || nameResult.length === 3) {
        return;
      }
      nameResult += word[0];
    });

    return nameResult;
  }

  logout() {
    this.progressService.start();
    this.authService.logout()
      .pipe(
        take(1),
        finalize(() => this.progressService.complete()),
        untilDestroyed(this),
      ).subscribe();
  }
}
