import { Component, Input } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

/**
 * Interface for css class
 */
interface CssClass {
  container: string;
}

/**
 * Component for preloader
 */
@Component({
    selector: 'pmd-preloader',
    templateUrl: './preloader.component.html',
    styleUrls: ['./preloader.component.scss'],
    standalone: true,
    imports: [MatProgressSpinner],
})
export class PreloaderComponent {
  @Input()
  public cssClass: CssClass = {
    container: '',
  };
}
