import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesPerDateGQL extends Query<any> {
  document = gql`
    query activities($in_clipboard: Boolean!, $date: date!, $templateId: bigint!) {
      activities(where: { in_clipboard: { _eq: $in_clipboard }, date: { _eq: $date }, templateId: { _eq: $templateId} }) {
        id
        note
        date
        phaseDefault
        nextActivityId
        previousActivityId
        duration
        done
        description
        fixedStart
        templateId
        in_clipboard
        order_in_clipboard
        order_in_phase
        status
        phase {
          id
        }
        priority {
          id
          description
        }
        appointment {
          id
          extId
          extLink
        }
        task {
          id
          is_implicit
          description
          group {
            id
            title
          }
        }
      }
      phases(where: { date: { _eq: $date }, templateId: { _eq: $templateId} }, order_by: { fixedStart: asc }) {
        id
        date
        fixedStart
        name
        templateId
        orderActivities
        activities { id }
      }
    }
  `;
}
