import { inject, Injectable } from '@angular/core';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationService } from '../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { loadePrioritiesTreeAction, loadPrioritiesTreeSuccessAction, showToastPrioritiesAction } from './priorities.actions';
import { GraphqlService } from '../../core/services/graphql.service';
import { ShowToastEffect } from '../common/show-toast-effect/show-toast-effect';

@Injectable()
export class PrioritiesEffect extends ShowToastEffect {

    constructor(
        private actions$: Actions,
        private _graphqlService: GraphqlService,
        private notificationService: NotificationService,
        private translate: TranslateService,
    ) {
        super(notificationService, translate);
    }

    loadPriorities$ = createEffect(() => this.actions$.pipe(
        ofType(loadePrioritiesTreeAction),
        exhaustMap(() =>
            this._graphqlService.getPriorities()
            .pipe(
                map((result) => loadPrioritiesTreeSuccessAction({ responce: JSON.parse(JSON.stringify(result.data.priorities)) })),
                catchError((error: { message: string }) =>
                    of(showToastPrioritiesAction({ toastType: 'error',  errorMsg: error.message })),
                ),
            )),
        ),
    );

    showToastAction$ = createEffect(
        () => {
            return inject(Actions)
            .pipe(
                ofType(showToastPrioritiesAction),
                tap(({ toastType,  errorMsg }) => this.showNotification(toastType, errorMsg)),
            );
    },
    { functional: true, dispatch: false });
}
