import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export const SEVEN_DAY_IN_MILLISECONDS = 604800000;
export const LAST_DAY_IN_WEEK = 7;

export const week = [
  {
    name: marker('Mon'),
    date: null,
  },
  {
    name: marker('Tue'),
    date: null,
  },
  {
    name: marker('Wed'),
    date: null,
  },
  {
    name: marker('Thu'),
    date: null,
  },
  {
    name: marker('Fri'),
    date: null,
  },
  {
    name: marker('Sat'),
    date: null,
  },
  {
    name: marker('Sun'),
    date: null,
  },
];
