import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeleteGroupContainerByPkGQL } from '../../../../../modules/day-planner/components/planning-board/graphql/deleteGroupContainerByPkGQL';
import { UpdateGroupContainerByPkGQL } from '../../../../../core/services/graphql-queries/updateGroupContainerByPkGQL';
import { CreateGroupContainerGQL } from '../graphql/createGroupContainerGQL';
import { FetchAllGroupContainersGQL } from '../graphql/fetchAllGroupContainersGQL';
import { UpdateGroupContainerStatusGQL } from '../graphql/updateGroupContainerStatusGQL';
import {
  AssignedGroupContainer,
  GroupContainerStatus,
  GroupContainerStatusKey,
  PlanningBoardContainer,
  PlanningBoardTask,
} from '../helpers/planning-board.helper';
import { FetchGroupsWithTaskGQL } from '../graphql/fetchGroupsWithTaskGQL';
import { map } from 'rxjs/operators';
import { FetchGroupContainerStatusesGQL } from '../graphql/fetchGroupContainerStatusesGQL';
import { FinishGroupGQL } from '../graphql/finishGroupGQL';
import { CancelGroupGQL } from '../graphql/cancelGroupGQL';
import { RemoteSchemaCascadeMutationOptions } from '../helpers/remote-schema.types';

import ShouldBe = RemoteSchemaCascadeMutationOptions
import { FetchActiveGroupsWithActiveTasksGQL } from '../graphql/fetchActiveGroupsWithActiveTasksGQL';
export type BigInt = number;
export type ChangeGroupStatusResponse = {
  errors: string[],
  group: PlanningBoardContainer,
  changedTasks: PlanningBoardTask[],
}


@Injectable({providedIn: 'root'})
export class GroupContainersService {
  constructor(
    private updateGroupContainerByPkGQL: UpdateGroupContainerByPkGQL,
    private createGroupContainerGQL: CreateGroupContainerGQL,
    private deleteGroupContainerByPkGQL: DeleteGroupContainerByPkGQL,
    private fetchAllGroupContainersGQL: FetchAllGroupContainersGQL,
    private updateGroupContainerStatusGQL: UpdateGroupContainerStatusGQL,
    private fetchGroupsWithTaskGQL: FetchGroupsWithTaskGQL,
    private fetchActiveGroupsWithActiveTasksGQL: FetchActiveGroupsWithActiveTasksGQL,
    private fetchGroupContainerStatusesGQL: FetchGroupContainerStatusesGQL,
    private finishGroupGQL: FinishGroupGQL,
    private cancelGroupGQL: CancelGroupGQL,
  ) {
  }

  public fetchGroupsWithTask(): Observable<AssignedGroupContainer[]> {
    return this.fetchGroupsWithTaskGQL.watch({}, {fetchPolicy: 'no-cache'}).valueChanges
      .pipe(map((res) => res['data']['groups']));
  }

  public fetchActiveGroupsWithActiveTasks(): Observable<AssignedGroupContainer[]> {
    return this.fetchActiveGroupsWithActiveTasksGQL.watch({}, {fetchPolicy: 'no-cache'}).valueChanges
      .pipe(map((res) => res['data']['groups']));
  }

  public createGroupContainer$(title: string, background: string, width: number, position: string): Observable<any> {
    return this.createGroupContainerGQL.mutate({title, background, width, position});
  }

  public deleteByPk(id: number): Observable<any> {
    return this.deleteGroupContainerByPkGQL.mutate({id});
    // TODO - add to archive
  }

  public fetchAll(): Observable<{ data: { groups: PlanningBoardContainer[] } }> {
    return this.fetchAllGroupContainersGQL.watch({}, {fetchPolicy: 'no-cache'}).valueChanges;
  }

  public update(container: PlanningBoardContainer): Observable<any> {
    return this.updateGroupContainerByPkGQL.mutate({
      id: container.id,
      width: container.width,
      background: container.background,
      position: container.position,
      title: container.title,
    });
  }

  // STATUSES
  fetchStatuses$(): Observable<GroupContainerStatus[]> {
    return this.fetchGroupContainerStatusesGQL.watch({}, {fetchPolicy: 'no-cache'}).valueChanges.pipe(map(
      (res) => res['data']['statuses'],
    ));
  }

  updateStatus$(id: BigInt, status: GroupContainerStatusKey): Observable<PlanningBoardContainer> {
    return this.updateGroupContainerStatusGQL.mutate({id, status}).pipe(map(
      (res) => res['data']['update_groups_by_pk'],
    ));
  }

  finishGroup$(
    id: BigInt,
    unfinishedTasks: ShouldBe.Canceled | ShouldBe.Finished | null,
  ): Observable<ChangeGroupStatusResponse> {
    return this.finishGroupGQL.mutate({id, unfinishedTasks}).pipe(map(
      (res) => res['data']['finishGroup'],
    ));
  }

  cancelGroup$(id: BigInt): Observable<ChangeGroupStatusResponse> {
    return this.cancelGroupGQL.mutate({id}).pipe(map(
      (res) => res['data']['cancelGroup'],
    ));
  }
}
