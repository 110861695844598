<div class="c-durationEntry" [ngClass]="{'c-durationEntry--column': column}" [matMenuTriggerFor]="menu">
  <p class="c-durationEntry__title">
    <span>{{ convertMinToHr(currentDuration) }} &#x20;</span>
    <span *ngIf="currentDuration < 60">{{'min' | translate}}</span>
    <span *ngIf="currentDuration >= 60">{{'hr' | translate}}</span>
    <mat-icon class="c-durationEntry__titleIcon">expand_more</mat-icon>
  </p>

  <mat-menu #menu="matMenu" class="c-durationEntry__menu">
    <button class="c-durationEntry__menuItem"
            [class.active]="currentDuration === dur"
            *ngFor="let dur of durations" (click)="selectDuration(dur)" mat-menu-item>
      <span>{{ convertMinToHr(dur) }} &#x20;</span>
      <span *ngIf="dur < 60">{{'min' | translate}}</span>
      <span  *ngIf="dur >= 60">{{'hr' | translate}}</span>
    </button>
  </mat-menu>
</div>
