import { ApplicationConfig, importProvidersFrom, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { NgProgressModule } from 'ngx-progressbar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CookieModule } from 'ngx-cookie';
import { provideAnimations } from '@angular/platform-browser/animations';
import { appRoutes } from './app.routes';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorsInterceptor } from '../app/core/interceptors/errors.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { LanguageService } from '../app/shared/translates/language.service';
import { UserLocaleService } from '../app/shared/services/user-locale.service';
import { AppSettingsService } from '../app/core/services/app-settings.service';
import { AuthService } from '../app/modules/auth/services/auth.service';
import { AppInitializer } from '../app/_helpers/appInitializer';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { PreloaderService } from './shared/components/preloader/preloader.service';
import { PlanningBoardClipboardService } from './modules/day-planner/components/planning-board/services/planning-board-clipboard.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateParser,
} from '@ngx-translate/core';
import { translateLoaderFactory, translateParserFactory } from './shared/translates/translate-helper';
import { MissingTranslationService } from './shared/translates/missing-translation-service.service';
import { DynamicLocaleId } from './shared/translates/dynamic-locale.id';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { provideStore } from '@ngrx/store';
import { reducers, storeEffects } from './store/app.state';
import { provideEffects } from '@ngrx/effects';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const appConfig: ApplicationConfig = {
  providers: [
    provideStore(reducers),
    provideEffects(storeEffects),
    importProvidersFrom(
      BrowserModule,
      CommonModule,
      appRoutes,
      MatSidenavModule,
      MatListModule,
      MatIconModule,
      MatButtonModule,
      MatMenuModule,
      MatRippleModule,
      MatSlideToggleModule,
      MatDatepickerModule,
      MatNativeDateModule,
      CookieModule.withOptions({expires: new Date().getFullYear() + 1 + '-12-31T22:00:00.000Z'}),
      RouterModule,
      NgProgressModule,
      ApolloModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: translateLoaderFactory,
        },
        parser: {
          provide: TranslateParser,
          useFactory: translateParserFactory,
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: MissingTranslationService,
        },
        useDefaultLang: true,
      }),
      MsalModule.forRoot(new PublicClientApplication({
          auth: {
            clientId: '172cbb8a-39db-4be5-8507-55f949023ba8',
            authority: 'https://login.microsoftonline.com/common/',
            redirectUri: environment.frontendHost,
          },
          cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
          },
      }),
      {
        interactionType: InteractionType.Popup,
        authRequest: {
          scopes: ['user.read', 'calendars.read', 'calendars.read.shared'],
        },
      },
      {
        interactionType: InteractionType.Popup,
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', ['user.read', 'calendars.read', 'calendars.read.shared']],
        ]),
      }),
    ),
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
              uri: 'v1/graphql',
          }),
        };
      },
      deps: [HttpLink],
    },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: LOCALE_ID, deps: [LanguageService], useClass: DynamicLocaleId },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializer,
      multi: true,
      deps: [AuthService, AppSettingsService, UserLocaleService, LanguageService, PreloaderService],
    },
    UserLocaleService,
    PreloaderService,
    PlanningBoardClipboardService,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideHotToastConfig(),
  ],
};
