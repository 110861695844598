import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  FormatWidth,
  getLocaleDateFormat,
  getLocaleId,
  getLocaleTimeFormat,
  registerLocaleData,
} from '@angular/common';
import { ILocale } from 'locale-codes';
import * as locale from 'locale-codes';
import { CookieService } from 'ngx-cookie';
import { NotificationService } from '../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable()
export class UserLocaleService {
  userLocaleId$: BehaviorSubject<string> = new BehaviorSubject<string>(this.cookie.get('locale') || navigator.language);
  private _localeModule = null;

  private _format = {
    time: 'HH:mm',
    date: 'yyyy-MM-dd',
  };

  constructor(
    private cookie: CookieService,
    private notificationService: NotificationService,
    private translate: TranslateService,
  ) {
  }

  get localesList(): ILocale[] {
    return locale.all;
  }

  setLocale(locale: string) {
    this.registerLocale(locale).catch((e) => {
      const slicedLocale = locale.slice(0, 2);
      this.registerLocale(slicedLocale).then().catch((error) => {
        this.notificationService.error({
          title: this.translate.instant(marker('Locales error')),
          description: this.translate.instant(marker('Cannot get this locale')),
        });
      });
    });
  }

  private registerLocale(locale: string) {
    return import(`/node_modules/@angular/common/locales/${locale}.mjs`).then((module) => {
      registerLocaleData(module.default);
      this._localeModule = module.default;
      this.userLocaleId$.next(getLocaleId(locale));
      this.cookie.put('locale', this.locale);
      this._format.date = getLocaleDateFormat(locale, FormatWidth.Short);
      this._format.time = getLocaleTimeFormat(locale, FormatWidth.Short);
    });
  }

  get locale() {
    return this.userLocaleId$.getValue();
  }

  get format() {
    return this._format;
  }

  get localeModule() {
    return this._localeModule;
  }

  public registerLocaleNg(locals): void {
    locals?.forEach((locale) => registerLocaleData(locale));
  }
}
