import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, catchError, finalize, mergeMap, of } from 'rxjs';
import { AuthService } from '../../../modules/auth/services/auth.service';
import { PreloaderService } from '../../../shared/components/preloader/preloader.service';
import { environment } from '../../../../environments/environment';
import { LOGIN_URL } from '../../../_helpers/constants.helper';

@Injectable({
  providedIn: 'root',
})
export class KeycloakAuthGuard  {
  constructor(
    private _authService: AuthService,
    private _preloaderService: PreloaderService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | void | UrlTree> | Promise<boolean | UrlTree> {
    const code = route.queryParams?.['code']?.trim();
    this._preloaderService.show(this);

    return this._authService.isLoggedIn$()
    .pipe(
        finalize(() => this._preloaderService.close(this)),
        mergeMap((isLoggedIn) => {
          if (!isLoggedIn) {
            // check the session code (is user authorized in Keycloak)
            if (!code) {
              // redirect to Keycloak form login
              window.location.assign(`${environment.frontendHost}${LOGIN_URL}redirect_uri=${window.location.origin}/app`);
              return of(false);
            } else {
              // server request to login
              return this._authService.login({ code, redirect_uri: `${window.location.origin}/app`});
            }

          } else {
            return of(true);
          }
      }),
      catchError(() => of(false)),
    );
  }
}
