import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class UpdateActivityByPkGQL extends Mutation {
  document = gql`
  mutation UpdateActivityByPkGQL(
    $id: bigint!,
    $done: Boolean,
    $in_clipboard: Boolean,
    $order_in_clipboard: Int = 10,
    $order_in_phase: Int = 10,
    $phase_id: bigint = "",
    $duration: Int = 10,
    $description: String = "",
    $priorityId: bigint = "",
  ) {
      update_activities_by_pk(pk_columns: {id: $id},
      _set: {in_clipboard: $in_clipboard,
      done: $done,
      order_in_clipboard: $order_in_clipboard,
      order_in_phase: $order_in_phase,
      phaseId: $phase_id,
      duration: $duration,
      description: $description,
      priorityId: $priorityId}) {
        id
      }
    }
  `;
}

