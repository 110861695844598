import { inject, Injectable } from '@angular/core';
import { catchError, exhaustMap, map, of, switchMap, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationService } from '../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import {
    addTemplateAction,
    cloneTemplateAction,
    createTemplateAction,
    deleteTemplateAction,
    deleteTemplateInStoreAction,
    loadePhaseTemplatesTreeAction,
    loadPhaseTemplatesTreeSuccessAction,
    showToastPhaseTemplatesAction,
    updateSelectedTemplateIdAction,
    updateTemplateAction,
    updateTemplateInStoreAction,
} from './phase-templates.actions';
import { GraphqlService } from '../../core/services/graphql.service';
import { ShowToastEffect } from '../common/show-toast-effect/show-toast-effect';
import { PhasesService } from '../../core/services/phases.service';

@Injectable()
export class PhaseTemplatesEffect extends ShowToastEffect {

    constructor(
        private _actions$: Actions,
        private _graphqlService: GraphqlService,
        private _phasesService: PhasesService,
        private notificationService: NotificationService,
        private translate: TranslateService,
    ) {
        super(notificationService, translate);
    }

    loadePhaseTemplates$ = createEffect(() => this._actions$.pipe(
        ofType(loadePhaseTemplatesTreeAction),
        exhaustMap(() =>
            this._graphqlService.getTemplates()
            .pipe(
                map((result) => loadPhaseTemplatesTreeSuccessAction({ responce: result.data.templates })),
                catchError((error: { message: string }) =>
                    of(showToastPhaseTemplatesAction({ toastType: 'error',  errorMsg: error.message })),
                ),
            )),
        ),
    );

    createTemplate$ = createEffect(() => this._actions$.pipe(
        ofType(createTemplateAction),
        switchMap((action) =>
            this._graphqlService.createTemplate(action.name, action.active)
            .pipe(
                tap(() => this.showNotification('success', action.msg)),
                exhaustMap((result) => {
                    return [
                        addTemplateAction({ template: result.data.createTemplate.template }),
                        updateSelectedTemplateIdAction({ id: result.data.createTemplate.template.id }),
                    ];
                }),
                catchError((error: { message: string }) =>
                    of(showToastPhaseTemplatesAction({ toastType: 'error',  errorMsg: error.message })),
                ),
            )),
        ),
    );

    cloneTemplate$ = createEffect(() => this._actions$.pipe(
        ofType(cloneTemplateAction),
        switchMap((action) =>
            this._graphqlService.cloneTemplate(action.selectedTemplateId, action.name)
            .pipe(
                tap(() => this.showNotification('success', action.msg)),
                exhaustMap((result) => {
                    return [
                        addTemplateAction({ template: result.data.cloneTemplate.template }),
                        updateSelectedTemplateIdAction({ id: result.data.cloneTemplate.template.id }),
                    ];
                }),
                catchError((error: { message: string }) =>
                    of(showToastPhaseTemplatesAction({ toastType: 'error',  errorMsg: error.message })),
                ),
            )),
        ),
    );

    editTemplate$ = createEffect(() => this._actions$.pipe(
        ofType(updateTemplateAction),
        switchMap((action) =>
            this._graphqlService.updateTemplate(action.templateId, action.name, action.active)
            .pipe(
                tap(() => this.showNotification('success', action.msg)),
                exhaustMap(() => {
                    return [
                        updateTemplateInStoreAction({ update: {
                            id: action.templateId,
                            changes: {
                                name: action.name,
                                active: action.active,
                                default: false,
                            },
                        }}),
                        updateSelectedTemplateIdAction({ id: action.templateId }),
                    ];
                }),
                catchError((error: { message: string }) =>
                    of(showToastPhaseTemplatesAction({ toastType: 'error',  errorMsg: error.message })),
                ),
            )),
        ),
    );

    deleteTemplate$ = createEffect(() => this._actions$.pipe(
        ofType(deleteTemplateAction),
        switchMap((action) =>
            this._graphqlService.deleteTemplate(action.selectedTemplateId)
            .pipe(
                tap(() => this.showNotification('success', action.msg)),
                exhaustMap((resp) => {
                    return [
                        deleteTemplateInStoreAction({ id: action.selectedTemplateId }),
                        updateSelectedTemplateIdAction({ id: undefined }),
                    ];
                }),
                catchError((error: { message: string }) =>
                    of(showToastPhaseTemplatesAction({ toastType: 'error',  errorMsg: error.message })),
                ),
            )),
        ),
    );

    showToastAction$ = createEffect(
        () => {
            return inject(Actions)
            .pipe(
                ofType(showToastPhaseTemplatesAction),
                tap(({ toastType,  errorMsg }) => this.showNotification(toastType, errorMsg)),
            );
    },
    { functional: true, dispatch: false });
}
