import { Component, OnInit } from '@angular/core';
import { THEME_KEYS, ThemeService } from '../../../services/theme.service';
import { AsyncPipe } from '@angular/common';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
    selector: 'theme-switcher',
    templateUrl: './theme-switcher.component.html',
    styleUrls: ['./theme-switcher.component.scss'],
    standalone: true,
    imports: [MatSlideToggle, AsyncPipe],
})
export class ThemeSwitcherComponent implements OnInit {
  darkModeEnabled$ = this.themeService.darkModeEnabled$;

  constructor(private themeService: ThemeService) { }

  ngOnInit(): void { }

  public switchTheme(): void {
    this.themeService.toggleDarkTheme(this.themeService.isDark ? THEME_KEYS.Light : THEME_KEYS.Dark);
  }
}
