<ng-progress [id]="'globalProgressbar'"
             [speed]="200"
             [trickleSpeed]="250"
             [spinner]="false"
             [thick]="true"
             ease="linear"
             direction="ltr+"
             color="#28ba62"></ng-progress>

<router-outlet></router-outlet>

<app-clipboard *ngIf="showClipboard"></app-clipboard>
