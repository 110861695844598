<div class="c-appointments">
  <ng-container *ngIf="getAllAppointmentsLoaded else appointmentsLoading">
    <ng-container *ngIf="someAppointmentsIntegrationEnabled else appointmentsDisabled">
      <ng-container *ngIf="appointments.length else appointmentsEmpty">
        <div class="c-appointmentsList">
          <ng-container *ngFor="let appointment of appointments">
            <app-appointment-list-entry [appointment]="appointment"
                                        class="c-appointmentsList__item"
                                        [class.planned]="appointment.planned"></app-appointment-list-entry>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #appointmentsEmpty>
        <div class="c-phasesList__empty p-16 m-auto">
          <p class="c-phasesList__emptyTitle">{{'You have no appointments for this day' | translate}}</p>
        </div>
      </ng-template>
      <div class="p-2.5">
        <button class="btn" (click)="getAllAppointments()">{{'Update appointments' | translate}}</button>
      </div>
    </ng-container>
    <ng-template #appointmentsDisabled>
      <div class="c-phasesList__empty px-5 py-10 m-auto">
        <p class="c-phasesList__emptyTitle">{{'You do not have integration enabled' | translate}}</p>
        <p class="c-phasesList__emptyText">{{'Please add some integration in settings' | translate}}</p>
        <div class="flex items-center justify-center">
          <app-button btnStyle="outline" routerLink="/settings/integrations">
            {{'Add integration' | translate}}
          </app-button>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #appointmentsLoading>
    <div class="px-5 py-10">
      <spinner size="sm"></spinner>
    </div>
  </ng-template>
</div>
