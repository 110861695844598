import { inject, Injectable } from '@angular/core';
import { catchError, exhaustMap, finalize, map, of, switchMap, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    updateTaskActivityInlipboardAction,
    deleteActivityFromClipboardAction,
    deleteActivityFromClipboardStoreAction,
    loadActivitiesTreeSuccessAction,
    loadeActivitiesTreeAction,
    updateActivityAction,
    updateActivityInStoreAction,
    updateActivityPriorityAction,
    addActivityToClipboardAction,
    addActivityToClipboardStoreAction,
    sortDayFromClipboardStoreAction,
    showToastClipboardAction,
} from './clipboard-activities.actions';
import { ActivitiesService } from '../../modules/day-planner/components/planning-board/services/activities.service';
import { NotificationService } from '../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { NgProgressService } from '../../shared/services/ng-progress.service';
import { ShowToastEffect } from '../common/show-toast-effect/show-toast-effect';
import { addActivityInStoreAction, deleteActivityInStoreAction } from '../activities/activities.actions';
import { PhasesService } from '../../core/services/phases.service';
import { Phase } from 'src/app/core/models/interfaces';

@Injectable()
export class ClipboardActivitiesEffect extends ShowToastEffect {

    constructor(
        private actions$: Actions,
        private activitiesService: ActivitiesService,
        private _phasesService: PhasesService,
        private progressService: NgProgressService,
        private notificationService: NotificationService,
        private translate: TranslateService,
    ) {
        super(notificationService, translate);
    }

    loadActivities$ = createEffect(() => this.actions$.pipe(
        ofType(loadeActivitiesTreeAction),
        exhaustMap(() =>
            this.activitiesService.getAllClipboardActivities()
            .pipe(
                map((activities) => {
                    return loadActivitiesTreeSuccessAction({ responce: activities.data.activities });
                }),
                catchError((error: { message: string }) =>
                    of(showToastClipboardAction({ toastType: 'error',  errorMsg: error.message })),
                ),
            )),
        ),
    );

    addClipboardActivity$ = createEffect(() => this.actions$.pipe(
        ofType(addActivityToClipboardAction),
        tap(() => this.progressService.start()),
        switchMap((action) =>
            this.activitiesService.updateActivityByPk$(action.activity)
            .pipe(
                finalize(() => this.progressService.complete()),
                exhaustMap(() => {
                    return [
                        addActivityToClipboardStoreAction({ activity: action.activity }),
                        deleteActivityInStoreAction({ id: action.activity.id }),
                        sortDayFromClipboardStoreAction({
                            date: typeof action.activity.date === 'string' ? action.activity.date : action.activity.date?.toFormat('yyyy-MM-dd'),
                            templateId: action.activity.templateId,
                            msg: action.msg,
                        }),
                    ];
                }),
                catchError((error: { message: string }) =>
                    of(showToastClipboardAction({ toastType: 'error',  errorMsg: error.message })),
                ),
            )),
        ),
    );

    deleteActivityFromClipboard$ = createEffect(() => this.actions$.pipe(
        ofType(deleteActivityFromClipboardAction),
        tap(() => this.progressService.start()),
        switchMap((action) =>
            this.activitiesService.deleteActivityFromClipboardAndReturnCorrespondingTaskIfItShouldNotBeClipboard$(action.activity)
            .pipe(
                finalize(() => this.progressService.complete()),
                exhaustMap(() => {
                    const act = {
                        ...action.activity,
                        phase: {
                            id: action.activity?.phase?.id || action.activity.phaseId,
                        } as Phase,
                        date: typeof action.activity.date === 'string' ? action.activity.date : action.activity.date?.toFormat('yyyy-MM-dd'),
                        templateId: action.activity?.templateId ?? -1,
                    };

                    return [
                        deleteActivityFromClipboardStoreAction({ id: act.id }),
                        addActivityInStoreAction({ activity: act }),
                        sortDayFromClipboardStoreAction({
                            date: act.date,
                            templateId: action.activity?.templateId,
                            msg: action.msg,
                        }),
                    ];
                }),
                catchError((error: { message: string }) =>
                    of(showToastClipboardAction({ toastType: 'error',  errorMsg: error.message })),
                ),
            )),
        ),
    );

    updateActivity$ = createEffect(() => this.actions$.pipe(
        ofType(updateActivityAction),
        tap(() => this.progressService.start()),
        exhaustMap((action) =>
            this.activitiesService.updateActivityByPk$(action.activity)
            .pipe(
                finalize(() => this.progressService.complete()),
                map(() => {
                    return updateActivityInStoreAction({ update: {
                        id: action.activity.id,
                        changes: action.activity,
                    } });
                }),
                tap(() => this.showNotification('success', action.msg)),
                catchError((error: { message: string }) =>
                    of(showToastClipboardAction({ toastType: 'error',  errorMsg: error.message })),
                ),
            )),
        ),
    );

    assignTaskToActivity$ = createEffect(() => this.actions$.pipe(
        ofType(updateTaskActivityInlipboardAction),
        tap(() => this.progressService.start()),
        exhaustMap((action) =>
            this.activitiesService.assignTaskToActivity(action.activity.id, action.activity.task?.id ?? null)
            .pipe(
                finalize(() => this.progressService.complete()),
                map(() => {
                    this.activitiesService.newActivityAssignedTask$.next({ oldActivity: action.oldActivity, newActivity: action.activity});
                    return updateActivityInStoreAction({ update: {
                        id: action.activity.id,
                        changes: action.activity,
                    } });
                }),
                tap(() => this.showNotification('success', action.msg)),
                catchError((error: { message: string }) =>
                    of(showToastClipboardAction({ toastType: 'error',  errorMsg: error.message })),
                ),
            )),
        ),
    );

    updateActivityPriority$ = createEffect(() => this.actions$.pipe(
        ofType(updateActivityPriorityAction),
        tap(() => this.progressService.start()),
        exhaustMap((action) =>
            this.activitiesService.updateActivityPriority(action.changes.activityId, action.changes.priorityId)
            .pipe(
                finalize(() => this.progressService.complete()),
                map((resp) => {
                    return updateActivityInStoreAction({ update: {
                        id: action.changes.activityId,
                        changes: { priority: resp.data.update_activities_by_pk.priority },
                    } });
                }),
                tap(() => this.showNotification('success', action.msg)),
                catchError((error: { message: string }) =>
                    of(showToastClipboardAction({ toastType: 'error',  errorMsg: error.message })),
                ),
            )),
        ),
    );

    sortDayAction$ = createEffect(
        () => {
            return inject(Actions)
            .pipe(
                ofType(sortDayFromClipboardStoreAction),
                tap((action) => {
                    this._phasesService.sortDay$(action.date, action.templateId)
                        .subscribe(() => this._phasesService.refreshDays());
                }),
                tap((action) => this.showNotification('success', action.msg)),
            );
    },
    { functional: true, dispatch: false });

    showToastAction$ = createEffect(
        () => {
            return inject(Actions)
            .pipe(
                ofType(showToastClipboardAction),
                tap(({ toastType,  errorMsg }) => this.showNotification(toastType, errorMsg)),
            );
    },
    { functional: true, dispatch: false });
}
