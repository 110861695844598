import {
  Component,
  OnDestroy,
  OnInit,
  Signal,
  signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal,
} from '@angular/core';
import { Phase, Priority } from '../../../../core/models/interfaces';
import { DataproviderService } from '../../../../core/services/dataprovider.service';
import { DateTime } from 'luxon';
import { MatDialog } from '@angular/material/dialog';
import { startWith, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatTabGroup } from '@angular/material/tabs';
import { CookieService } from 'ngx-cookie';
import { UserLocaleService } from '../../../../shared/services/user-locale.service';
import {
  DayEditorOnboardingIdEnum,
  getDayEditorOnboardingTooltips,
} from '../../../../core/components/objects/onboarding-tooltips/onboarding-tooltips.helper';
import { OnboardingDialogComponent } from '../dialogs/onboarding-dialog/onboarding-dialog.component';
import { OnboardingTooltipsComponent } from '../../../../core/components/objects/onboarding-tooltips/onboarding-tooltips.component';
import { DayEditorHeaderComponent } from './components/day-editor-header/day-editor-header.component';
import { DayEditorBodyComponent } from './components/day-editor-body/day-editor-body.component';
import { PlanningBoardClipboardParent } from '../planning-board/helpers/planning-board.helper';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { selectAllPriorities } from '../../../../store/priorities/priorities.selectors';
import { PhasesService } from '../../../../core/services/phases.service';

@UntilDestroy()
@Component({
    selector: 'app-day-editor',
    templateUrl: './day-editor.component.html',
    styleUrls: ['./day-editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        OnboardingTooltipsComponent,
        DayEditorHeaderComponent,
        DayEditorBodyComponent,
    ],
})
export class DayEditorComponent implements OnInit, OnDestroy {
  @ViewChild('rightTabGroup') rightTabGroup: MatTabGroup;
  readonly DayEditorOnboardingIdEnum = DayEditorOnboardingIdEnum;
  readonly PlanningBoardClipboardParent = PlanningBoardClipboardParent;

  public phases: Signal<Phase[]> = this._phasesService.allPhaseByDaySignal;
  public priorities: WritableSignal<Priority[]> = signal<Priority[]>([]);

  public date: string = DateTime.now().toFormat('yyyy-MM-dd');
  public selectedDate: Date = new Date();
  public dayEditorOnboardingTooltips = null;

  constructor(
    private _store: Store<AppState>,
    private _phasesService: PhasesService,
    private _dataProviderService: DataproviderService,
    private _dialog: MatDialog,
    private _cookie: CookieService,
    public userLocaleService: UserLocaleService,
  ) { }

  ngOnInit(): void {
    this._phasesService.onRefreshDays$
      .pipe(startWith(undefined), untilDestroyed(this))
      .subscribe(() => this._refreshData());

    this._store.select(selectAllPriorities)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (priorities) => {
          this.priorities.set(priorities);
        },
      });

    this._showFirstOnboardingDialog();
  }

  public setDate(event: { selectedDate: Date; date: string; }): void {
    this.selectedDate = event.selectedDate;
    this.date = event.date;
    this._dataProviderService.fetchAllActivitiesAndPhases(event.date);
  }

  private _showFirstOnboardingDialog(): void {
    const dayEditorOnboardingTooltips = getDayEditorOnboardingTooltips(this.phases().length > 0);
    if (!this._cookie.hasKey('first-onboarding-dialog')) {
      if (this._cookie.hasKey(Object.keys(dayEditorOnboardingTooltips)[0])) {
        this._cookie.remove(Object.keys(dayEditorOnboardingTooltips)[0]);
      }
      this._dialog.open(OnboardingDialogComponent, {disableClose: true}).afterClosed()
        .pipe(take(1), untilDestroyed(this))
        .subscribe(() => {
          this.dayEditorOnboardingTooltips = dayEditorOnboardingTooltips;
        });
    } else {
      this.dayEditorOnboardingTooltips = dayEditorOnboardingTooltips;
    }
  }

  private _refreshData(): void {
    this._phasesService.patchPhasesByDay(this.date);
  }

  ngOnDestroy(): void {
    this._phasesService.patchPhasesByDay(null);
  }
}


