import { DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
import {
  DayEditorOnboardingIdEnum,
} from '../../../../../../core/components/objects/onboarding-tooltips/onboarding-tooltips.helper';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { DateTime } from 'luxon';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { LAST_DAY_IN_WEEK, SEVEN_DAY_IN_MILLISECONDS, week } from '../../../../../../core/constants/date';

@Component({
    selector: 'day-editor-header',
    templateUrl: './day-editor-header.component.html',
    styleUrls: ['./day-editor-header.component.scss'],
    standalone: true,
    imports: [
      ReactiveFormsModule,
      NgClass,
      NgIf,
      NgFor,
      TranslateModule,
      DatePipe,
      MatDatepicker,
      MatDatepickerToggle,
      MatDatepickerInput,
      MatIcon,
      MatTooltip,
    ],
})
export class DayEditorHeaderComponent implements OnInit {
  @Output() onSetDateEvent: EventEmitter<{ selectedDate: Date; date: string; }> = new EventEmitter();

  public navigationWeekMonth = new Date();
  public week = week;
  public date: string = DateTime.now().toFormat('yyyy-MM-dd');
  public selectedDateControl: UntypedFormControl = new UntypedFormControl(this.date);
  public selectedDate: Date = new Date();
  public today = new Date();
  public readonly DayEditorOnboardingIdEnum = DayEditorOnboardingIdEnum;

  private _currentDay = this.cookie.hasKey('selectedDate')
    ? new Date(JSON.parse(this.cookie.get('selectedDate')) || 'null')
    : new Date();


  constructor(private cookie: CookieService) { }

  ngOnInit(): void {
    this.setDate(this._currentDay);
    this._setTodayIfNewDayHasCome();
  }

  public isSelectedDay(date: Date): boolean {
    return date.toDateString() === this.selectedDate.toDateString();
  }

  public isToday(date: Date): boolean {
    return date.toDateString() === new Date().toDateString();
  }

  public prevWeek(): void {
    this._currentDay = new Date(this._currentDay.getTime() - SEVEN_DAY_IN_MILLISECONDS);
    this._getWeekDays(this._currentDay);
  }

  public nextWeek(): void {
    this._currentDay = new Date(this._currentDay.getTime() + SEVEN_DAY_IN_MILLISECONDS);
    this._getWeekDays(this._currentDay);
  }

  public setDate(event: Date): void {
    if (event) {
      this.selectedDate = event;
      this.date = DateTime.fromJSDate(event).toFormat('yyyy-MM-dd');
      this.selectedDateControl.patchValue(event);
      this._currentDay = event;
      this._getWeekDays(event);
      this.cookie.put('selectedDate', JSON.stringify(this._currentDay));
      this.onSetDateEvent.emit({
        selectedDate: event,
        date: this.date,
      });
    }
  }

  private _getWeekDays(date: Date): void {
    const day = new Date(date);
    for (let i = 1; i <= this.week.length; i++) {
      let first = day.getDate() - (day.getDay() === 0 ? LAST_DAY_IN_WEEK : day.getDay()) + i;
      this.week[i - 1].date = new Date(day.setDate(first));
      this.navigationWeekMonth = this.week[0].date;
    }
  }

 private _setTodayIfNewDayHasCome(): void {
    setInterval(() => {
      if (this.today.getDate() === new Date().getDate() - 1) {
        this.setDate(new Date());
        window.location.reload();
      }
    }, 300000);
  }

}
