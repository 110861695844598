import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Activity } from '../../../models/interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgTemplateOutlet, NgFor, NgClass } from '@angular/common';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { AssignedGroupContainer, AssignedGroupContainerTask } from '../../../../modules/day-planner/components/planning-board/helpers/planning-board.helper';
import { asyncScheduler } from 'rxjs';

@Component({
    selector: 'activity-assigned-task',
    templateUrl: './activity-assigned-task.component.html',
    styleUrls: ['./activity-assigned-task.component.scss'],
    standalone: true,
    imports: [
        MatMenuTrigger,
        NgIf,
        NgTemplateOutlet,
        MatMenu,
        NgFor,
        MatIcon,
        NgClass,
        TranslateModule,
    ],
})
export class ActivityAssignedTaskComponent implements AfterViewInit {
  @Input() activity: Activity;
  @Input() trigger: TemplateRef<any>;
  @Input() groupsWithTasks: AssignedGroupContainer[];

  @Output() assignTaskToActivityEmit: EventEmitter<{
    oldActivity: Activity;
    task: AssignedGroupContainerTask;
    group: AssignedGroupContainer;
  }> = new EventEmitter();

  @Output() removeTaskBindingEmit: EventEmitter<{ oldActivity: Activity }> = new EventEmitter();

  constructor() { }

  ngAfterViewInit() {
    asyncScheduler.schedule(() => {
      this.groupHaveAssignedTask();
    }, 15);
  }

  get activityGroup(): AssignedGroupContainer {
    if (!this.activity.task || !this.activity.task.group) {
      return null;
    }

    return this.groupsWithTasks.find((group) => group.id === this.activity.task.group.id);
  }

  get activityGroupTask(): AssignedGroupContainerTask {
    if (!this.activity.task || !this.activityGroup) {
      return null;
    }

    return this.activityGroup.tasks.find((task) => task.id === this.activity.task.id);
  }

  public removeTaskBinding(): void {
    const oldActivity = this.prepareCopyActivity(this.activity);
    this.removeTaskBindingEmit.emit({ oldActivity });
  }

  public assignTaskToActivity(task: AssignedGroupContainerTask, group: AssignedGroupContainer): void {
    if (this.activity.task?.id === task.id) {
      return;
    }
    const oldActivity = this.prepareCopyActivity(this.activity);
    this.assignTaskToActivityEmit.emit({ task, group, oldActivity });
  }

  private groupHaveAssignedTask(): void {
    this.groupsWithTasks.forEach((group) => {
      group.expanded = this.activityGroup?.id === group.id;
    });
  }

  private prepareCopyActivity(activity: Activity): Activity {
    return {
      id: activity.id,
      task: activity.task,
      in_clipboard: activity.in_clipboard,
      date: activity.date,
      description: activity.description,
      duration: activity.duration,
      note: activity.note,
      order_in_clipboard: activity.order_in_clipboard,
      order_in_phase: activity.order_in_phase,
      order_in_task: activity.order_in_task,
      templateId: activity.templateId,
      priority: activity.priority,
      status: activity.status,
      done: activity.done,
      appointment: activity.appointment,
    };
  }
}
