import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { Priority } from '../../core/models/interfaces';
import * as fromPrioritiesReducer from './priorities.reducer';

export const selectFeature = createFeatureSelector<AppState>('priorities');

export const selectAllPriorities = createSelector<AppState, any, Priority[]>(
  selectFeature,
  fromPrioritiesReducer.selectAllPriorities,
);

export const selectPrioritiesEntities = createSelector<AppState, any, any>(
  selectFeature,
  fromPrioritiesReducer.selectPrioritiesEntities,
);

export const selectPriority = (priorityrId: number) => createSelector(
  selectPrioritiesEntities,
  (priorityrEntities) => priorityrEntities[priorityrId],
);
