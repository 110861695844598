import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    standalone: true,
})
export class SpinnerComponent implements OnInit {
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  constructor() { }

  ngOnInit(): void {
  }

}
