import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Priority } from '../../core/models/interfaces';
import { loadPrioritiesTreeSuccessAction } from './priorities.actions';

export interface PrioritiesState extends EntityState<Priority> { }

const adapter: EntityAdapter<Priority> = createEntityAdapter<Priority>({
  selectId: (item: Priority) => item.id,
  sortComparer: false,
});

const initialState: PrioritiesState = adapter.getInitialState([]);

const _PrioritiesStoreReducer = createReducer<PrioritiesState>(
  initialState,
  on(loadPrioritiesTreeSuccessAction, (state, { responce }) => {
    return adapter.upsertMany(responce, state);
  }),
);

export function prioritiesStoreReducer(state: any, action: Action) {
  return _PrioritiesStoreReducer(state, action);
}

const {
  selectEntities,
  selectAll,
} = adapter.getSelectors();

// select the dictionary of Priorities entities
export const selectPrioritiesEntities = selectEntities;

// select the array of Priorities
export const selectAllPriorities = selectAll;
