import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class UpdatePhaseGQL extends Mutation {
  document = gql`
    mutation MyMutation($id: bigint!, $fixedStart: time, $name: String, $orderActivities: String) {
      update_phases_by_pk(pk_columns: {id: $id}, _set: {fixedStart: $fixedStart, name: $name, orderActivities: $orderActivities}) {id}
    }`;
}
