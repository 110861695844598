import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Template } from '../../core/models/interfaces';
import {
  addTemplateAction,
  deleteTemplateInStoreAction,
  loadPhaseTemplatesTreeSuccessAction,
  updateSelectedTemplateIdAction,
  updateTemplateInStoreAction,
} from './phase-templates.actions';

export interface PhaseTemplatesState extends EntityState<Template> {
  selectedTemplateId: string | number | null;
}

const adapter: EntityAdapter<Template> = createEntityAdapter<Template>({
  selectId: (item: Template) => item.id,
  sortComparer: false,
});

const initialState: PhaseTemplatesState = adapter.getInitialState({
  selectedTemplateId: null,
});

const _phaseTemplatesStoreReducer = createReducer<PhaseTemplatesState>(
  initialState,
  on(loadPhaseTemplatesTreeSuccessAction, (state, { responce }) => {
    return adapter.upsertMany(responce, state);
  }),
  on(updateSelectedTemplateIdAction, (state, { id }) => {
    return { ...state, selectedTemplateId: id };
  }),
  on(addTemplateAction, (state, { template }) => {
    return adapter.addOne(template, state);
  }),
  on(updateTemplateInStoreAction, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(deleteTemplateInStoreAction, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
);

export function phaseTemplatesStoreReducer(state: any, action: Action): PhaseTemplatesState {
  return _phaseTemplatesStoreReducer(state, action);
}

const {
  selectEntities,
  selectAll,
} = adapter.getSelectors();

export const getSelectedTemplateId = (state: PhaseTemplatesState): string | number => state.selectedTemplateId;

// select the dictionary of phaseTemplates entities
export const selectPhaseTemplatesEntities = selectEntities;

// select the array of phaseTemplates
export const selectAllPhaseTemplates = selectAll;
