import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';
import { AssignedGroupContainer } from '../helpers/planning-board.helper';

@Injectable({providedIn: 'root'})
export class FetchGroupsWithTaskGQL extends Query<AssignedGroupContainer[]> {
  document = gql`
    query Groups {
      groups {
        id
        title
        status
        background
        tasks {
          id
          description
        }
      }
    }
  `;
}
