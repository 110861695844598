import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Activity, Priority } from '../../../../../../core/models/interfaces';
import { filter, finalize, switchMap, take } from 'rxjs/operators';
import { NotificationService } from '../../../../../../core/services/notification.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  AssignedGroupContainer,
  ActivityStatus,
  PlanningBoardActivityParent,
  AssignedGroupContainerTask,
} from '../../helpers/planning-board.helper';
import { NgProgressService } from '../../../../../../shared/services/ng-progress.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { ConfirmationService } from '../../../../../../core/services/confirmation.service';
import { MatIconButton } from '@angular/material/button';
import { ActivityAssignedTaskComponent } from '../../../../../../core/components/objects/activity-assigned-task/activity-assigned-task.component';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { DurationEntryComponent } from '../../../../../../core/components/objects/duration-entry/duration-entry.component';
import { MatIcon } from '@angular/material/icon';
import { CdkDrag, CdkDragPlaceholder, CdkDragPreview } from '@angular/cdk/drag-drop';
import { NgIf, NgStyle, NgFor, NgClass } from '@angular/common';
import { PlanningBoardClipboardService } from '../../services/planning-board-clipboard.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../store/app.state';
import {
  updateTaskActivityInlipboardAction,
  deleteActivityFromClipboardAction,
  updateActivityAction,
  updateActivityPriorityAction,
} from '../../../../../../store/clipboard-activities/clipboard-activities.actions';

@UntilDestroy()
@Component({
    selector: 'planning-board-activity',
    templateUrl: './planning-board-activity.component.html',
    styleUrls: ['./planning-board-activity.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        CdkDrag,
        CdkDragPlaceholder,
        CdkDragPreview,
        NgStyle,
        MatIcon,
        DurationEntryComponent,
        MatTooltip,
        MatMenuTrigger,
        MatMenu,
        NgFor,
        NgClass,
        ActivityAssignedTaskComponent,
        MatIconButton,
        MatMenuItem,
        TranslateModule,
    ],
})
export class PlanningBoardActivityComponent {
  @Input() parent: PlanningBoardActivityParent = 'clipboard';
  @Input() activity: Activity;
  @Input() activityStatuses: ActivityStatus[] = [];
  @Input() priorities: Priority[] = [];
  @Input() groupsWithTasks: AssignedGroupContainer[] = [];

  @Output() cloneActivity: EventEmitter<void> = new EventEmitter();
  @Output() activityRemoved: EventEmitter<number> = new EventEmitter();

  public cdkDragPreviewWidth: number;

  constructor(
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private progressService: NgProgressService,
    private router: Router,
    private cookie: CookieService,
    private planningBoardClipboardService: PlanningBoardClipboardService,
    private confirmationService: ConfirmationService,
  ) { }

  get cannotToClipboardTooltip(): string {
    if (this.activity.in_clipboard) {
      return marker('This activity is on the clipboard');
    }

    if (!!this.activity.phase) {
      return marker('This activity is already scheduled in my day');
    }

    if (this.activity.done) {
      return marker('This activity has already been completed');
    }

    return marker('Move to clipboard');
  }

  public cdkDragStarted(event: any): void {
    this.cdkDragPreviewWidth = event.source.element.nativeElement.offsetWidth / 2;
  }

  // moveToClipboard() {
  //   if (!!this.activity.phase || this.activity.in_clipboard) {
  //     return;
  //   }

  //   this.planningBoardClipboardService.addClipboardActivity(this.activity)
  //     .pipe(take(1), untilDestroyed(this)).subscribe(() => {
  //     if (!this.planningBoardClipboardService.clipboardVisible$.getValue()) {
  //       this.planningBoardClipboardService.toggleVisibility(true);
  //     }
  //   });
  // }

  removeActivity() {
    this.confirmationService.openDialog(marker('Are you sure want to delete this activity?'))
      .pipe(
        filter((isConfirmed) => !!isConfirmed),
        take(1),
        switchMap(() => {
          this.progressService.start();
          return this.planningBoardClipboardService.deleteActivity(this.activity);
        }),
        finalize(() => this.progressService.complete()),
        untilDestroyed(this),
      ).subscribe(() => {
        this.activityRemoved.emit(this.activity.id);
        this.notificationService.success({
          description: this.translate.instant(marker('Activity deleted successfully')),
        });
      });
  }

  public removeTaskBinding(event: { oldActivity: Activity }): void {
    this.store.dispatch(updateTaskActivityInlipboardAction({
      oldActivity: event.oldActivity,
      activity: {
        ...this.activity,
        task: null,
      },
      msg: marker('Task binding successfully removed'),
    }));
  }

  public assignTaskToActivity(event: {
    task: AssignedGroupContainerTask;
    group: AssignedGroupContainer;
    oldActivity: Activity
  }): void {
    this.store.dispatch(updateTaskActivityInlipboardAction({
      oldActivity: event.oldActivity,
      activity: {
        ...this.activity,
        task: {
          id: event.task.id,
          is_implicit: event.task.is_implicit,
          description: event.task.description,
          group: {
            id: event.group.id,
            title: event.group.title,
          },
        },
      },
      msg: marker('Task successfully assigned'),
    }));
  }

  public removeFromClipboard(): void {
    this.store.dispatch(deleteActivityFromClipboardAction({
      activity: { ...this.activity, in_clipboard: false },
      msg: marker('Activity successfully deleted from clipboard'),
    }));
  }

  public updatePriority(priorityId: number): void {
    if (priorityId === this.activity.priority.id) {
      return;
    }

    this.store.dispatch(updateActivityPriorityAction({
      changes: {
        activityId: this.activity.id,
        priorityId,
      },
      msg: 'Task priority successfully changed',
    }));
  }

  public durationChanged($event: string): void  {
    this.store.dispatch(updateActivityAction({
      activity: { ...this.activity, duration: +$event },
      msg: 'Task duration successfully changed',
    }));
  }

  public updateTitle(value: string): void {
    if (this.activity.description === value) {
      return;
    }

    this.store.dispatch(updateActivityAction({
      activity: { ...this.activity, description: value },
      msg: 'Task description successfully changed',
    }));
  }

  public doneActivity(): void {
    this.store.dispatch(updateActivityAction({
      activity: { ...this.activity, done: !this.activity.done },
      msg: null,
    }));
  }

  public goToActivityInMyDay(): void {
    this.cookie.put('selectedDate', JSON.stringify(this.activity.date));
    this.router.navigate(['/day-editor'], {
      queryParams: {
        activityId: this.activity.id,
      },
    });
  }
}
