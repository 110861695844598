import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const THEME_LOCAL_STORAGE_KEY = 'planmy-day-theme-preference';
export enum THEME_KEYS {
  Dark = 'dark',
  Light = 'light',
}
type ThemeModes = 'dark' | 'light';

@Injectable({providedIn: 'root'})
export class ThemeService {
  public darkModeEnabled$ = new BehaviorSubject<boolean>(false);
  private _prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

  constructor() {}

  get isDark(): boolean {
    return (localStorage.getItem(THEME_LOCAL_STORAGE_KEY) === THEME_KEYS.Dark);
  }

  public initTheme(themeType: ThemeModes): void {
    this._prefersDark.addEventListener('change', (mediaQuery) => {
      this.darkModeEnabled$.next(mediaQuery.matches);
      this.toggleDarkTheme(mediaQuery.matches ? THEME_KEYS.Dark : THEME_KEYS.Light);
    });
    this.toggleDarkTheme(themeType);
  }

  public toggleDarkTheme(themeType: ThemeModes): void {
    localStorage.setItem(THEME_LOCAL_STORAGE_KEY, themeType);
    this.darkModeEnabled$.next(this.isDark);
    document.body.classList.toggle('dark', this.isDark);
  }
}
