import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationResult } from '@azure/msal-browser';
import { environment } from '../../../../environments/environment';
import { Calendar } from '../../google/services/google-calendar.service';
import { map, switchMap, take } from 'rxjs/operators';
import { GraphqlService } from '../../../core/services/graphql.service';
import { Appointment } from '../../../core/models/interfaces';
import { DateTime } from 'luxon';
import { convertUTCDateToLocalDate } from '../../../_helpers/helpers';
import { DataproviderService } from '../../../core/services/dataprovider.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { selectAllActivities } from '../../../store/activities/activities.selectors';

export interface OutlookCalendarResponse {
  value: OutlookCalendarValueResponse[],
}

export interface OutlookCalendarValueResponse {
  id: string;
  name: string;
  color: string;
  changeKey: string;
  canShare: boolean;
  canViewPrivateItems: boolean;
  hexColor: string;
  canEdit: boolean;
  allowedOnlineMeetingProviders: string[];
  defaultOnlineMeetingProvider: string;
  isTallyingResponses: boolean;
  isRemovable: string;
  owner: {
    name: string;
    address: string;
  };
  enabled: boolean;
  configured: boolean;
}

export interface OutlookCalendarEventsResponse {
  value: OutlookCalendarEvent[];
}

export interface OutlookCalendarEvent {
  id: string;
  createdDateTime: string;
  lastModifiedDateTime: string;
  changeKey: string;
  categories: any[];
  transactionId: string;
  originalStartTimeZone: string;
  originalEndTimeZone: string;
  iCalUId: string;
  reminderMinutesBeforeStart: number;
  isReminderOn: true;
  hasAttachments: false;
  subject: string;
  bodyPreview: string;
  importance: string;
  sensitivity: string;
  isAllDay: boolean;
  isCancelled: boolean;
  isOrganizer: boolean;
  responseRequested: boolean;
  seriesMasterId: any;
  showAs: string;
  type: string;
  webLink: string;
  onlineMeetingUrl: string;
  isOnlineMeeting: boolean;
  onlineMeetingProvider: string;
  allowNewTimeProposals: boolean;
  occurrenceId: any;
  isDraft: boolean;
  hideAttendees: boolean;
  responseStatus: {
    response: string;
    time: string;
  },
  body: {
    contentType: string;
    content: string;
  },
  start: {
    dateTime: string;
    timeZone: string;
  },
  end: {
    dateTime: string;
    timeZone: string;
  },
  location: {
    displayName: string;
    locationType: string;
    uniqueIdType: string;
    address: object;
    coordinates: object;
  },
  locations: any[],
  recurrence: any,
  attendees: any[],
  organizer: {
    emailAddress: {
      name: string;
      address: string;
    }
  },
  onlineMeeting: any,
}

@Injectable({providedIn: 'root'})
export class ExchangeOnlineCalendarService {
  private _exchangeOnlineJwtData: AuthenticationResult = null;
  private _exchangeOnlineCalendars: Calendar[] = [];

  constructor(
    private _store: Store<AppState>,
    private http: HttpClient,
    private graphqlService: GraphqlService,
  ) { }

  setExchangeOnlineJwtData(data: AuthenticationResult) {
    this._exchangeOnlineJwtData = data;
  }

  get exchangeOnlineJwtData(): AuthenticationResult | null {
    return this._exchangeOnlineJwtData;
  }

  selectCalendar(calendar: Calendar): Observable<any> {
    return calendar.configured
      ? this.graphqlService.updateCalendarInConfig(calendar.id, calendar.enabled)
      : this.graphqlService.insertCalendarToConfig(calendar.id, calendar.enabled);
  }

  getListCalendars(): Observable<Calendar[]> {
    return this.http.get<OutlookCalendarResponse>(environment.microsoftGraphUrl + '/me/calendars')
      .pipe(
        map((res) => {
          this._exchangeOnlineCalendars = res.value.map((cal) => {
            return {
              id: cal.id,
              summary: cal.name,
              enabled: false,
              configured: false,
            };
          });
          return this._exchangeOnlineCalendars;
        }),
        switchMap(() => this.graphqlService.getCalendarSelectionConfig()),
        map((calendarsConfig) => {
          return this._exchangeOnlineCalendars.map((calendar) => {
            const calendarFromConfig = calendarsConfig.data.calendar_selection_config.find((confCalendar) => confCalendar.calendar_id === calendar.id);
            if (calendarFromConfig) {
              calendar.enabled = calendarFromConfig.enabled;
              calendar.configured = true;
            }
            return calendar;
          });
        }),
      );
  }

  getListCalendarGroup(calendarId: string) {
    return this.http.get(environment.microsoftGraphUrl + `/me/calendarGroups/${calendarId}`);
  }

  getListEvents(calendarId: string, date: string): Observable<Appointment[]> {
    const startDateTime = new Date(new Date(date).setUTCHours(0, 0, 0, 0)).toISOString();
    const endDateTime = new Date(new Date(date).setUTCHours(23,59,59,999)).toISOString();
    return this.http.get<OutlookCalendarEventsResponse>(environment.microsoftGraphUrl + `/me/calendars/${calendarId}/calendarView?startDateTime=${startDateTime}&endDateTime=${endDateTime}`)
      .pipe(map((events) => {
        let activities = [];
        this._store.select(selectAllActivities).pipe(take(1)).subscribe((acts) => activities = acts);

        return events.value.map((event) => {
          return {
            extId: event.id,
            extLink: event.webLink,
            summary: event.subject,
            startTime: DateTime.fromJSDate(!event.isAllDay ? convertUTCDateToLocalDate(new Date(event.start.dateTime)) : new Date(event.start.dateTime)),
            endTime: DateTime.fromJSDate(!event.isAllDay ? convertUTCDateToLocalDate(new Date(event.end.dateTime)) : new Date(event.end.dateTime)),
            allDay: event.isAllDay,
            provider: 'microsoft',
            planned: activities.some((act) => act.appointment ? act.appointment.extId === event.id : false),
          };
        });
      }));
  }
}
